<ion-app [class.dark-theme]="dark">
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" style="width: auto">
      <ion-content style="--background: none">
        <ion-list lines="none">
          <ion-list-header style="margin-bottom: 10%">
            <ion-img src="../assets/img/logos/logo-clinica-grupoperezespinosa v Registro de marca.jpg"></ion-img>
          </ion-list-header>
          <ion-menu-toggle autoHide="false">
            <ion-item button (click)="navigateToRoot('control-enfermeria')" style="margin-bottom: 5%">
              <ion-icon slot="start" name="id-card-outline"></ion-icon>
              <ion-label> Control de enfermería </ion-label>
            </ion-item>
            <ion-item button (click)="navigateToRoot('informes')" style="margin-bottom: 5%">
              <ion-icon slot="start" name="reader-outline"></ion-icon>
              <ion-label> Informes </ion-label>
            </ion-item>
            <ion-item
              button
              (click)="navigateToRoot('gestion')"
              id="gestionBt"
              class="noVisible"
              style="margin-bottom: 5%"
            >
              <ion-icon slot="start" name="person-outline"></ion-icon>
              <ion-label> Gestión clínica </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle autoHide="false">
            <ion-item button detail="false" (click)="navigateToRoot('menu')" style="margin-bottom: 5%">
              <ion-icon slot="start" name="grid-outline"></ion-icon>
              <ion-label>Menú</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <!-- <ion-list lines="none">
          <ion-list-header>
            Tutorial
          </ion-list-header>
          <ion-menu-toggle autoHide="false">
            <ion-item button detail="false" (click)="openTutorial()">
              <ion-icon slot="start" name="book-outline"></ion-icon>
              <ion-label>Ver tutorial</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list> -->

        <!--
        <ion-list lines="none">
          
          <ion-item button (click)="toggle()" disabled>
            <ion-icon slot="start" name="moon-outline"></ion-icon>
            <ion-label>
              Modo oscuro
            </ion-label>
            <ion-toggle [(ngModel)]="dark"></ion-toggle>
          </ion-item>
        </ion-list>
        -->

        <ion-list lines="none">
          <ion-menu-toggle autoHide="false">
            <ion-item button detail="false" (click)="logout()" color="dangerSoft">
              <ion-icon slot="start" name="log-out-outline"></ion-icon>
              <ion-label>Cerrar sesión</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <ion-list>
          <img src="" />
        </ion-list>
      </ion-content>
      <ion-footer>
        <ion-item
          style="font-size: 10pt; margin-top: 10px; margin-bottom: 10px"
          class="registro-auditoria"
          [routerLink]="'/registro-auditoria'"
          [ngClass]="{ selected: isCurrentUrl('/registro-auditoria') }"
        >
          Registro de acceso
        </ion-item>
      </ion-footer>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
