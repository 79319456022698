import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CallbackPageModule } from "./pages/callback/callback.module";
import { CheckTutorial } from "./providers/check-tutorial.service";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "tutorial",
    loadChildren: () => import("./pages/tutorial/tutorial.module").then((m) => m.TutorialModule),
    canLoad: [CheckTutorial],
  },
  {
    path: "menu",
    loadChildren: () => import("./pages/menu/menu.module").then((m) => m.MenuPageModule),
  },
  {
    path: "control-enfermeria",
    loadChildren: () =>
      import("./pages/control-enfermeria/control-enfermeria.module").then((m) => m.ControlEnfermeriaPageModule),
  },
  {
    path: "nueva-visita",
    loadChildren: () => import("./pages/nueva-visita/nueva-visita.module").then((m) => m.NuevaVisitaPageModule),
  },
  {
    path: "detalles-paciente",
    loadChildren: () =>
      import("./pages/detalles-paciente/detalles-paciente.module").then((m) => m.DetallesPacientePageModule),
  },
  {
    path: "informes",
    loadChildren: () => import("./pages/informes/informes.module").then((m) => m.InformesPageModule),
  },
  {
    path: "informe-unico",
    loadChildren: () => import("./pages/informe-unico/informe-unico.module").then((m) => m.InformeUnicoPageModule),
  },
  {
    path: "ingreso",
    loadChildren: () => import("./pages/ingreso/ingreso.module").then((m) => m.IngresoPageModule),
  },
  {
    path: "informes-paciente",
    loadChildren: () =>
      import("./pages/informes-paciente/informes-paciente.module").then((m) => m.InformesPacientePageModule),
  },
  {
    path: "control-constantes",
    loadChildren: () =>
      import("./pages/control-constantes/control-constantes.module").then((m) => m.ControlConstantesPageModule),
  },
  {
    path: "new-control",
    loadChildren: () => import("./pages/new-control/new-control.module").then((m) => m.NewControlPageModule),
  },
  {
    path: "tratamiento-sueno",
    loadChildren: () =>
      import("./pages/tratamiento-sueno/tratamiento-sueno.module").then((m) => m.TratamientoSuenoPageModule),
  },
  {
    path: "gestion",
    loadChildren: () => import("./pages/gestion/gestion.module").then((m) => m.GestionPageModule),
  },
  {
    path: "gestion-pacientes",
    loadChildren: () =>
      import("./pages/gestion/gestion-pacientes/gestion-pacientes.module").then((m) => m.GestionPacientesPageModule),
  },
  {
    path: "gestion-empleados",
    loadChildren: () =>
      import("./pages/gestion/gestion-empleados/gestion-empleados.module").then((m) => m.GestionEmpleadosPageModule),
  },
  {
    path: "gestion-medicamentos",
    loadChildren: () =>
      import("./pages/gestion/gestion-medicamentos/gestion-medicamentos.module").then(
        (m) => m.GestionMedicamentosPageModule
      ),
  },
  {
    path: "edit-incidences",
    loadChildren: () =>
      import("./pages/edit-incidences/edit-incidences.module").then((m) => m.EditIncidencesPageModule),
  },
  {
    path: "informes-empleado",
    loadChildren: () =>
      import("./pages/informes-empleado/informes-empleado.module").then((m) => m.InformesEmpleadoPageModule),
  },
  {
    path: "api/fitbit/auth",
    children: [
      {
        path: "**",
        loadChildren: () => import("./pages/callback/callback.module").then((m) => m.CallbackPageModule),
      },
    ],
  },
  {
    path: "tratamiento",
    loadChildren: () => import("./pages/tratamiento/tratamiento.module").then((m) => m.TratamientoPageModule),
  },
  {
    path: "actividad-diaria",
    loadChildren: () =>
      import("./pages/actividad-diaria/actividad-diaria.module").then((m) => m.ActividadDiariaPageModule),
  },
  {
    path: "cuestionarios-seguimiento",
    loadChildren: () =>
      import("./pages/seguimiento/cuestionarios-seguimiento/cuestionarios-seguimiento.module").then(
        (m) => m.CuestionariosSeguimientoPageModule
      ),
  },
  {
    path: "editar-cuestionario",
    loadChildren: () =>
      import("./pages/seguimiento/editar-cuestionario/editar-cuestionario.module").then(
        (m) => m.EditarCuestionarioPageModule
      ),
  },
  {
    path: "respuestas-cuestionarios",
    loadChildren: () =>
      import("./pages/seguimiento/respuestas-cuestionarios/respuestas-cuestionarios.module").then(
        (m) => m.RespuestasCuestionariosPageModule
      ),
  },
  {
    path: "invitar-pacientes",
    loadChildren: () =>
      import("./pages/seguimiento/invitar-pacientes/invitar-pacientes.module").then(
        (m) => m.InvitarPacientesPageModule
      ),
  },
  {
    path: "test-inicial",
    loadChildren: () =>
      import("./pages/seguimiento/test-inicial/test-inicial.module").then((m) => m.TestInicialPageModule),
  },
  {
    path: "admit-external-person",
    loadChildren: () =>
      import("./pages/admit-external-person/admit-external-person.module").then((m) => m.AdmitExternalPersonPageModule),
  },
  {
    path: "politica-de-privacidad",
    loadChildren: () =>
      import("./pages/politica-de-privacidad/politica-de-privacidad.module").then(
        (m) => m.PoliticaDePrivacidadPageModule
      ),
  },  {
    path: 'registro-auditoria',
    loadChildren: () => import('./pages/registro-auditoria/registro-auditoria.module').then( m => m.RegistroAuditoriaPageModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
