import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AlertController, LoadingController, MenuController, NavController, Platform } from "@ionic/angular";
import { StatusBar } from "@capacitor/status-bar";
import { SplashScreen } from "@capacitor/splash-screen";
import * as $ from "jquery";
import { Storage } from "@ionic/storage";
export const serverRoute = "http://localhost:3004";
// export const serverRoute = "https://cpe-api4life.herokuapp.com";
// export const serverRoute = "https://desarrollo.i4life.es/cpe/prod";
// export const serverRoute = "https://desarrollo.i4life.es/cpe/dev";
// export const serverRoute = "http://192.168.1.241:8080";
// export const serverRoute = "https://cpe-dev.herokuapp.com";
// export const serverRouteSeguimiento = "http://192.168.1.135:3000";
export const serverRouteSeguimiento = "https://desarrollo.i4life.es/cpe-seguimiento/prod";
// export const serverRouteSeguimiento = "https://desarrollo.i4life.es/cpe-seguimiento/dev";
// export const serverRouteSeguimiento = "http://localhost:3002";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  routesWithoutMenu: string[] = ["/login", "/signup"];
  loggedIn = false;
  dark = false;

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private navCtrl: NavController,
    private loadingController: LoadingController,
    private alertCtrl: AlertController,
    private storage: Storage
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.storage.get("i4life-cpe-token").then((token: string) => {
          if (
            (token === null || token === undefined || token.trim() === "") &&
            event.url !== "/login" &&
            event.url !== "/politica-de-privacidad"
          ) {
            this.navCtrl.navigateRoot("login");
            this.storage.remove("i4life-cpe-employeeName");
            this.storage.remove("i4life-cpe-employeeID");
          }
        });

        this.storage.get("i4life-cpe-rol").then((rol) => {
          if (rol == "admin") {
            $("#gestionBt").removeClass("noVisible");
          } else if (!$("#gestionBt").hasClass("noVisible")) {
            $("#gestionBt").addClass("noVisible");
          }
        });
      }
    });

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is("hybrid")) {
        StatusBar.hide();
        SplashScreen.hide();
      }
    });
  }

  async logout() {
    const loading = await this.loadingController.create({
      message: "",
    });
    // sessionStorage.clear();
    this.storage.remove("i4life-cpe-token");
    this.storage.remove("i4life-cpe-employeeName");
    this.storage.remove("i4life-cpe-employeeID");
    return this.navCtrl.navigateRoot("");
  }

  navigateToRoot(route: string) {
    this.navCtrl.pop();
    this.navCtrl.navigateRoot(route, { animated: true, animationDirection: "forward" });
  }

  openTutorial() {
    this.alertCtrl
      .create({
        header: "Aviso",
        message: "¡Esta función es solo para teléfono!",
        buttons: ["De acuerdo"],
      })
      .then((res) => {
        res.present();
      });
  }

  toggle() {
    if (this.dark) {
      this.dark = false;
    } else {
      this.dark = true;
    }
  }

  isCurrentUrl(url: string) {
    return this.router.url === url;
  }
}
