import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { Storage } from "@ionic/storage";
import { serverRoute } from "../../app.component";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  token: any;

  constructor(private zone: NgZone, private http: HttpClient, private storage: Storage) {
    this.storage.get("i4life-cpe-token").then((token) => {
      this.token = token;
    });
  }

  last_err: any = "";
  handleError(error: any) {
    if (String(error).includes("Canvas is already in use")) {
      return;
    } else {
      if (error != this.last_err) console.error(error);
    }

    if (error != this.last_err) {
      this.http
        .post(
          serverRoute + "/api/error/admin",
          { error: { err: Error(error).stack } },
          { headers: new HttpHeaders({ Authorization: `Bearer ${this.token}` }) }
        )
        .subscribe(
          (response) => {
            console.log(response);
          },
          (err) => {
            console.error("Ha ocurrido un error reportando los datos al servidor principal.", err);
          }
        );
      this.last_err = error;
    }
  }
}
